import React from 'react'
import Layout from "../components/layout"
import ExhibitionNavBar from '../components/exhibitionNavBar'
import { graphql, Link} from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES} from "@contentful/rich-text-types"
import SEO from "../components/seo"
import './exhibitions.css'

export default function Exhibitions(props) {
 
  const currentExhibition = props.data.allContentfulExhibitionCurrent.edges.filter(n => n.node.order === 1)[0].node.currentExhibition
        const options = {
            renderNode: {
              [INLINES.ASSET_HYPERLINK]: (node, children) => {
                return <a href={node.data.target.file.url} className="">{children}</a>
              },
              [BLOCKS.EMBEDDED_ASSET]: node => {
                return <img src={node.data.target.file.url} alt=""/>
              },
              [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                return <Link href={`/exhibitions/${node.data.target.slug}`} >{children}</Link>
              }
          }
        }

        const richText = currentExhibition === null? <h1>Coming soon</h1>:renderRichText(currentExhibition, options)

    return (
      <Layout>
        <SEO 
          title={`Current Exhibition`}
        />
        <h1 class="visibility-hidden">Exhibitions</h1>
        <ExhibitionNavBar bureauAudio = {false} />
          <div className='current-exhibtion-cont'>
            {richText}
          </div>
      </Layout>
    )
  }


export const pageQuery = graphql
`
query currentexhibition {
  allContentfulExhibitionCurrent {
    edges {
      node {
        currentExhibition {
          raw
          references {
            ... on ContentfulExhibition {
              # contentful_id is required to resolve the references
              contentful_id
              title
              slug
            }
            ... on ContentfulAsset {
              contentful_id
              __typename
              file {
                url
                contentType
              }
            }
          }
        }
        order
      }
    }
  }
}

`

